import * as React from 'react'
import Button, {ButtonProps} from "@mui/material/Button";

import {alpha, useTheme} from "@mui/material";

interface ButtonPrimaryProps extends ButtonProps {}
export default function ButtonPrimary({children, ...rest}:ButtonPrimaryProps){
    const theme = useTheme();
    return(
        <Button {...rest} sx={{
            background: theme.palette.secondary.main.toString(),
            color:'white',
            '&.Mui-disabled':{
                color:'black',
                background: alpha(theme.palette.secondary.main.toString(),0.3),
            },
            '&:hover':{
                background:'#EF8B6E',
            },
            paddingLeft:'24px',
            paddingRight:'24px',
            gap:'8px',
            textTransform:'none'
        }}>{children}</Button>
    )
}