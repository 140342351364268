import Box, {BoxProps} from "@mui/material/Box";
import {INSTRUCTIONS_DATA} from "../../data/errorInstructionsData";
import Typography from "@mui/material/Typography";
import {BsFillCircleFill, BsFillExclamationTriangleFill} from "react-icons/bs";
import {List, ListItem, ListItemText, ListSubheader} from "@mui/material";
import React from "react";

export const ErrorMessage = ({error, ...boxProps}: { error: Error } & BoxProps) => {
    return <Box padding={5} bgcolor={"#F8F9FA"} borderRadius={"10px"} {...boxProps} >
        {(() => {
            let currentError = INSTRUCTIONS_DATA.find((datum) => {
                return (datum.errorMessage === error.message.toString());
            });

            if (!currentError)
                // Simple errors
                return <Typography fontFamily={"TWKLausanne-300"} fontSize={"21px"} color={"#E10600"} sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}>{error.message}</Typography>;

            let {errorMessage, errorDescription, possibleCauses, possibleSolutions} = currentError;

            return (<>
                <Typography fontFamily={"TWKLausanne-550"} fontSize={"21px"} color={"#E10600"} sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}><BsFillExclamationTriangleFill color={"#E10600"} style={{marginRight: "8px"}}/>{errorMessage}
                </Typography>
                <Typography fontFamily={"TWKLausanne-300"} fontSize={"16px"}
                            color={"#5F6368"} sx={{paddingLeft: "30px"}}
                            maxWidth={'775px'}>{errorDescription}</Typography>
                <List sx={{display: "list-item", paddingTop: "64px", paddingBottom: "64px"}}>
                    <ListSubheader disableSticky sx={{paddingLeft: "0px", paddingBottom: '18px'}}
                                   style={{backgroundColor: "#F8F9FA"}}><Typography
                        fontFamily={"TWKLausanne-300"}
                        fontSize={"21px"}
                        color={"#000000"}>{"This may be because..."}</Typography></ListSubheader>
                    {possibleCauses?.map((cause, index) => {
                        return (
                            <ListItem sx={{paddingLeft: "0px", paddingY: "4px"}} key={index}>
                                <Typography sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }} color={"#DADCE0"} marginRight={"8px"}>
                                    <BsFillCircleFill color={"#DADCE0"}/>
                                </Typography>
                                <ListItemText sx={{color: "#707070"}}>
                                    <Typography fontFamily={'TWKLausanne-400'} fontSize={'16px'}>
                                        {cause}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        );
                    })}
                </List>
                <List sx={{paddingY: '0px'}}>
                    {
                        possibleSolutions?.map((solutions, index) => {
                            return (
                                <><ListItem key={index} sx={{
                                    paddingLeft: "0px",
                                    marginY: '0px',
                                    paddingTop: "24px",
                                    paddingBottom: "12px"
                                }}>
                                    <Typography color={'#000000'} fontFamily={"TWKLausanne-550"}
                                                fontSize={"21px"}>{"Possible solution " + (index + 1) + ""}</Typography>
                                </ListItem><List sx={{paddingY: '0px'}}>
                                    {solutions.map((instruction, index) => {
                                        return (
                                            <ListItem sx={{paddingLeft: "0px", paddingY: "6px", marginY: '0px'}}
                                                      key={index}>
                                                <Typography sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexWrap: "wrap",
                                                }} color={"#707070"} marginRight={"12px"}>{solutions.length > 1 ?
                                                    (index + 1) + "." :
                                                    <BsFillCircleFill color={"#DADCE0"}/>
                                                }</Typography><Typography color={"#707070"}
                                                                          fontFamily={"TWKLausanne-300"}>{instruction}</Typography>
                                            </ListItem>
                                        );
                                    })}
                                </List></>
                            );
                        })
                    }
                </List>
            </>);
        })()}
    </Box>;
}