import {Adb, AdbPacketData, AdbPacketInit} from "@yume-chan/adb";
import AdbWebUsbBackend from "@yume-chan/adb-backend-webusb";
import AdbWebCredentialStore from "@yume-chan/adb-credential-web";
import {InspectStream, pipeFrom, ReadableStream, WritableStream} from "@yume-chan/stream-extra";
import {AdbDevice} from "../data/adbDeviceRepository";

const CredentialStore = new AdbWebCredentialStore();
let lastSelectedDeviceSerial: string | undefined

/**
 * Attempts at connecting to USB device
 * @return ADB device if successful undefined otherwise
 */
export async function connect(reconnect?: boolean): Promise<AdbDevice> {
    let backend: AdbWebUsbBackend | undefined
    if (!reconnect) {
        backend = await AdbWebUsbBackend.requestDevice().catch(() => {
            throw new Error("Device Not Found.")
        });
        lastSelectedDeviceSerial = backend?.serial
    } else {
        backend = (await AdbWebUsbBackend.getDevices()).find(d => d.serial === lastSelectedDeviceSerial)
    }
    // Check if we got one device
    if (!backend) {
        throw new Error("Device Not Found.")
    }
    let serial = backend.serial;

    let readable: ReadableStream<AdbPacketData>;
    let writable: WritableStream<AdbPacketInit>;
    try {
        const streams = await backend.connect();

        // Use `InspectStream`s to intercept and log packets
        readable = streams.readable
            .pipeThrough(
                new InspectStream(() => {
                })
            );

        writable = pipeFrom(
            streams.writable,
            new InspectStream(() => {
            })
        );
    } catch (e: any) {
        alert(e.message)
        throw e;
    }

    async function dispose() {
        // Adb won't close the streams,
        // so manually close them.
        try {
            readable.cancel();
        } catch {
        }
        try {
            await writable.close();
        } catch {
        }
    }

    try {

        const device = await Promise.race([
            Adb.authenticate(
                {readable, writable},
                CredentialStore,
                undefined
            ).catch((err) => {
                alert(err);
                throw new Error('Devices failed to authenticate.')
            }),
            new Promise<never>((_, reject) => {
                setTimeout(reject.bind(null, new Error("Authentication timed out")), 5000)
            })
        ])

        device.disconnected.then(async () => {
            await dispose();
        }, async () => {
            await dispose();
        });

        return {device, serial};
    } catch (e: any) {
        throw e
    }
}
