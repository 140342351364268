import {
    Box,
    Button,
    CircularProgress,
    CssBaseline,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import React, {useState} from "react";
import FileCopy from "@mui/icons-material/FileCopy";
import CommandCenter from "../utils/commandCenter";
import {RawDemoCommandData} from "../config/demoData";
import copy from "copy-to-clipboard";
import CheckIcon from "@mui/icons-material/Check";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {CommandSendStatus, useCommandSendStatus} from "../hooks/useCommandSendStatus";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";


/**
 * Developer zone page
 */
export default function DeveloperZone() {
    const {prepareAndSendCommand} = CommandCenter();
    const exampleCommand = `{"type": "comcoder", "raw": "{0x12}10200{0x13}3{0x13}34{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME+60}{0x13}1{0x13}Trafikolycka{0x13}Personbil{0x13}Övrigt{0x13}{0x13}{0x13}Ringvägen 100{0x13}Stockholm{0x13}Stockholm{0x13}Gula huset{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}101-0090{0x16}Framme{0x16}Stockholm{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}La = 59° 22,0849'N    Lo = 018° 1,1303'E{0x13}Parkering avfart Haga Norra{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}"}`;
    const [input, setInput] = useState("")

    const [textCopied, setTextCopied] = useState<boolean>(false);
    const theme = useTheme();

    const {commandSendStatus, setCommandSendStatus} = useCommandSendStatus(3 * 1000);

    let command: RawDemoCommandData | undefined = undefined
    try {
        command = JSON.parse(input) as RawDemoCommandData;
    } catch (e) {
        // nop
    }

    // @ts-ignore
    return (
        <React.Fragment>
            <CssBaseline/>
            <Grid container direction="row" paddingLeft={5} marginTop={5} justifyContent="space-between"
                  paddingRight={5} marginRight={5} rowGap={"16px"}>
                <Grid item>
                    <Typography variant="h5">
                        Enter The Command To be Sent As JSON
                    </Typography>
                </Grid>
                <Grid item>
                    <a href={"https://www.documentation.evam.life/tech/demo_tool_commands_reference.html"}
                       target={'_blank'} rel={'noreferrer'}>
                        <Button sx={{
                            color: "black",
                            borderColor: "black",
                            textTransform: "none",
                            minHeight: "50px",
                            minWidth: "222px",
                        }} variant="outlined" onClick={() => {
                        }}><OpenInBrowserIcon style={{verticalAlign: "text-bottom"}}/>
                            Open commands
                        </Button></a>
                </Grid>
            </Grid>
            <Box marginLeft={5} marginTop={8} marginRight={5}>
                <Typography variant="body1" component="p" color={"#5F6368"} fontSize={"18px"}
                            fontFamily={"TWKLausanne-300"}>
                    Example: use this command to send a new case
                </Typography>
                <TextField
                    sx={{
                        background: "#F1F3F4",
                        "& .MuiInputBase-input": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontFamily: "TWKLausanne-400"
                        },
                    }}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Divider orientation={"vertical"} sx={{height: "36px", paddingRight: "16px"}}/>
                                <Tooltip sx={{}} title={textCopied ? <Typography style={{fontSize: "16px"}}><CheckIcon
                                        style={{
                                            fontSize: "inherit",
                                            verticalAlign: "text-bottom",
                                            marginRight: "4px"
                                        }}/> {"Copied"} </Typography> :
                                    <Typography style={{fontSize: "16px"}}>{"Copy"}</Typography>}
                                         placement={"bottom-end"} onMouseEnter={() => {
                                    setTextCopied(false);
                                }}>
                                    <IconButton disableRipple edge="end" color="primary" onMouseEnter={() => {
                                        setTextCopied(false);
                                    }} onMouseLeave={() => {
                                        setTimeout(() => {
                                            setTextCopied(false);
                                        }, 1000);
                                    }} onClick={() => {
                                        setTextCopied(true);
                                        copy(exampleCommand);
                                    }}>
                                        <FileCopy sx={{
                                            color: textCopied ? theme.palette.secondary.main.toString() : "#000000",
                                            marginLeft: "16px",
                                            marginRight: "16px",
                                            ":hover": {
                                                color: textCopied ? theme.palette.secondary.main.toString() : "#5F6368"
                                            }
                                        }}/>
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    defaultValue={exampleCommand}
                />
            </Box>
            <Box marginTop={5} marginLeft={5} marginRight={5}>
                <TextField inputProps={{// @ts-ignore
                    "data-testid": "command-textfield"
                }} InputProps={{
                    style: {
                        height: "100%",
                        fontFamily: "TWKLausanne-300"
                    }
                }} placeholder={"Paste your code in here..."} sx={{
                    background: "#E8EAED",
                    overflowY: "auto",
                }} fullWidth multiline minRows={20} value={input} onChange={e => setInput(e.target.value)}/>
                <Box width={"100%"} display={"flex"} justifyContent={"right"} marginTop={"48px"} marginBottom={"48px"}>
                    <Button data-testid={"send-command-button"} disabled={!command} sx={{
                        backgroundColor: theme.palette.secondary.main.toString(),
                        textTransform: "none",
                        height: "50px",
                        width: "222px",
                        ":hover": {
                            bgcolor: "#EF8B6E"
                        }
                    }} variant="contained" onClick={async (_: any) => {
                        if (!command) return
                        setCommandSendStatus(CommandSendStatus.SENDING);
                        console.log("Input: " + input);
                        console.log("Command: " + command.type);
                        await prepareAndSendCommand(command).then(() => {
                            setCommandSendStatus(CommandSendStatus.SENT);
                        });
                    }}>{commandSendStatus === CommandSendStatus.NOT_SENT ? "Send Command" : commandSendStatus === CommandSendStatus.SENDING ?
                        <CircularProgress sx={{color: "white"}}/> : commandSendStatus === CommandSendStatus.FAILED ?
                            <CancelRoundedIcon sx={{fontSize: "40px"}}/> :
                            <CheckCircleRoundedIcon sx={{fontSize: "40px"}}/>}</Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
