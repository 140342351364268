import {SelectedDemoRepository} from "../../data/selectedDemoRepository";
import {
    InMemorySelectedDemoRepository
} from "../data/inMemorySelectedDemoRepository";
import React from "react";
import {AdbDeviceRepository} from "../../data/adbDeviceRepository";
import {InMemoryAdbDeviceRepository} from "../data/inMemoryAdbDeviceRepository";

/**
 * Dependencies injected in the application
 */
export interface AppDependencies {
    selectedDemoRepo: SelectedDemoRepository,
    adbDeviceRepository: AdbDeviceRepository
}

let selectedDemoRepo = new InMemorySelectedDemoRepository()
let adbDeviceRepository = new InMemoryAdbDeviceRepository()

export const DEFAULT_APP_CONTEXT_VALUE: AppDependencies = {
    selectedDemoRepo: selectedDemoRepo,
    adbDeviceRepository: adbDeviceRepository
}

/**
 * Application context
 */
export const AppContext = React.createContext<AppDependencies>(
    DEFAULT_APP_CONTEXT_VALUE
)