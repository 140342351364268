import {useEffect, useState} from "react";

/**
 * Enum used for updating status of Adb commands.
 */
export enum CommandSendStatus {
    NOT_SENT,
    SENDING,
    SENT,
    FAILED
}

/**
 * Custom hook for handling command status.
 * @param timeout optional parameter for if the command resets to 'NOT_SENT' after a fixed interval(in ms)
 */
export function useCommandSendStatus(timeout?: number) {

    /**
     * State object representing command's current status.
     * @default NOT_SENT
     */
    const [commandSendStatus, setCommandSendStatus] = useState<CommandSendStatus>(CommandSendStatus.NOT_SENT);

    /**
     * useEffect hook for handling when the command either sends or fails. Only relevant if a timeout has been specified.
     */
    useEffect(() => {
        if (timeout && (commandSendStatus === CommandSendStatus.SENT || commandSendStatus === CommandSendStatus.FAILED)) {
            const timerId = setTimeout(() => {
                setCommandSendStatus(CommandSendStatus.NOT_SENT);
            }, timeout);
            /**
             * Cleanup function for resetting the setTimeout.
             */
            return () => {
                clearTimeout(timerId);
            };
        }
    }, [commandSendStatus, timeout]);

    /**
     * Returns array as const so that we can declare a useCommandSendStatus the same way we declare a useState hook with array destructuring.
     */
    return {commandSendStatus, setCommandSendStatus};
}