type InstructionSet = {
    errorMessage: string,
    errorDescription?: string,
    possibleCauses?: string[],
    possibleSolutions?: string[][]
}


const err1: InstructionSet = {
    errorMessage: "Failed to execute 'claimInterface' on 'USBDevice': Unable to claim interface.",
    errorDescription: "This error occurs when WebUSB cannot gain exclusive access to the USB interface. It is possible something else has already claimed it.",
    possibleCauses: ["Another application is using Adb on this device", "This is most likely Android Studio or a previous scrcpy command"],
    possibleSolutions: [
        ["Shut down Android Studio or any other application using this device."],
        ["If on Windows then try the following:", "Open the File Explorer", "Open the folder in which you have downloaded scrcpy (it should contain scrcpy.exe)", "Click on the top bar in which the path to this folder show (left of the search bar)", "Replace the path 'C:\\...' with 'cmd.exe'", "Press Enter, cmd should open", "Execute 'adb kill-server'", "Try to connect again by clicking the button below"],
        ["If on MacOs then try the following:", "Open terminal", "Paste this './Library/Android/sdk/platform-tools/adb kill-server'", "Click enter", "Now try to connect."],
        ["If on Ubuntu then try the following:", "Open terminal", "Paste this 'adb kill-server'", "Click enter", "Now try to connect."],
        ["Turn off \"USB Debugging\" in your device's settings.", "Revoke USB debugging authorisations.", "Turn \"USB Debugging\" back on in your device.", "Do NOT click the \"Connect\" button in the demo tool.", "On your device reject any requests to allow USB debugging which show", "Now click the \"Connect\" button in the demo tool", "Allow USB debugging when the request shows"],
        ["Clear browser cache(ctrl + shift + delete)."]
    ]
};

const err2: InstructionSet = {
    errorMessage: "Devices failed to authenticate.",
    errorDescription: "This is an internal error with webUSB.",
    possibleCauses: ["This can usually be solved by resetting permissions."],
    possibleSolutions: [["Turn off \"USB Debugging\" in your device's settings.", "Revoke USB debugging authorisations.", "Turn \"USB Debugging\" back on in your device.", "Do NOT click the \"Connect\" button in the demo tool.", "On your device reject any requests to allow USB debugging which show", "Now click the \"Connect\" button in the demo tool", "Allow USB debugging when the request shows"],]

};
const err3: InstructionSet = {
    errorMessage: "Device Not Found.",
    errorDescription: "Error finding device.",
    possibleCauses: ['User cancelled device picker.', 'Faulty equipment/browser'],
    possibleSolutions: [['When choosing a device be sure to select the correct device and click \'Connect\''], ['Try using a different device/browser/cable.']]
};


export const INSTRUCTIONS_DATA = [err1, err2, err3];

