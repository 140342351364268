import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import demos from "../config/demoData";
import {useContext} from "react";
import {AppContext} from "../framework/context/AppContext";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

const demoIdNameMap = new Map<string, string>();

demos.forEach((demo) => {
    demoIdNameMap.set(demo.id, demo.name);
});


/**
 * App header that sticks to the top
 */
function TopBar() {
    let context = useContext(AppContext);

    // Update the Selected demo
    let location = useLocation();
    if (location.pathname.includes("/demo/")) {
        // Demo page, set the demo based on location
        const pathSplit = location.pathname.split("/");
        context.selectedDemoRepo.set({demoId: pathSplit[pathSplit.length - 1]});
        //demoIsSelected = true;
    } else {
        // Not demo page
        context.selectedDemoRepo.set(null);
    }

    const demoSelected = (event: SelectChangeEvent) => {
        let id = event.target.value as string;
        if (id === "demos")
            goToDemosPage();
        else
            goToDemo(id);
    };

    function goToDemo(demoId: string) {
        navigate(`/demo/${demoId}`);
    }

    function goToDemosPage() {
        navigate("/");
    }

    const navigate = useNavigate();

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" elevation={0}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: {xs: "none", sm: "block"},
                            "&:hover": {
                                cursor: "pointer"
                            }
                        }}
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <img alt={"Evam"} height={"50px"} src={process.env.PUBLIC_URL + "EvamLogo.svg"}
                             style={{verticalAlign: "middle"}}/>
                    </Typography>
                    <FormControl sx={{m: 1, width: 250}}>
                        {/*<InputLabel id="demo-simple-select-label" shrink={false}>
                            {demoIsSelected ? '' : 'Demos'}
                        </InputLabel>*/}
                        <AppContext.Consumer>
                            {(ctx) => (
                                <Select
                                    inputProps={{
                                        id:'demo-simple-select',
                                        'data-testid':'demo-simple-select'
                                    }}
                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                    sx={{
                                        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                            border: "none"
                                        },
                                        textAlign: "right",
                                    }}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                boxShadow:'0px 1px 6px #00000042',
                                                background: '#E8EAED 0% 0% no-repeat padding-box',
                                                borderRadius:'5%',
                                                '& .MuiMenuItem-root': {
                                                    padding: 2,
                                                    backgroundColor: 'transparent',
                                                    ":hover":{
                                                        backgroundColor: 'rgba(255,255,255,0.25)'
                                                    },
                                                },
                                                "&& .Mui-selected": {
                                                    backgroundColor: 'rgba(0,0,0,0.05)',
                                                    ":hover":{
                                                        backgroundColor: 'rgba(0,0,0,0.0.05)',
                                                    },
                                                }
                                            },

                                        },
                                    }}
                                    autoWidth
                                    //labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={ctx.selectedDemoRepo.get()?.demoId || "demos"}
                                    onChange={demoSelected}
                                >
                                    <MenuItem key={"demos"} value={"demos"}>Demos</MenuItem>
                                    {
                                        Array.from(demoIdNameMap.keys()).map((key: string) => {
                                            let value = demoIdNameMap.get(key);
                                            return (<MenuItem key={key} value={key}>{value}</MenuItem>
                                            );
                                        })
                                    }

                                </Select>
                            )}

                        </AppContext.Consumer>

                    </FormControl>
                    <Button disabled={window.location.pathname === '/send-command'} style={{textTransform: "none"}} variant="text" sx={{
                        color: "black",
                        "&:hover": {
                            background: "none"
                        },
                        fontSize:'inherit'
                    }}
                            onClick={() => {
                                navigate("/send-command");
                            }}
                    >Developer zone</Button>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default TopBar;
