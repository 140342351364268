import {SelectedDemoRepository} from "../../data/selectedDemoRepository";
import {SelectedDemo} from "../../domain/selectedDemo";


export class InMemorySelectedDemoRepository implements SelectedDemoRepository {
    private selectedDemo: SelectedDemo|null = null

    get(): SelectedDemo|null {
        console.log("GET SelectedDemo: " + this.selectedDemo)
        return this.selectedDemo;
    }

    set(demo: SelectedDemo | null): void {
        this.selectedDemo = demo
        console.log("SET SelectedDemo: " + this.selectedDemo)
    }


}