import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {createTheme, Grid, ThemeProvider} from "@mui/material";
import {Root} from "./components/Root";
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import {Home} from "./pages/Home";
import Demo from "./components/Demo";
import DeveloperZone from "./pages/DeveloperZone";
import "./assets/fonts/TWKLausanne-550.ttf";
import {AppContextProvider} from "./framework/context/AppContextProvider";

/* istanbul ignore file */
// Root definition
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

// Theme goes here
// TODO theme the app based on user design
const theme = createTheme({
    typography: {
        fontFamily: "TWKLausanne-400"
    },
    palette: {
        mode: "light",
        primary: {main: "#FF5F2E"},
        secondary: {main: "#FF7B52"},
        text: {primary: "#000000"}
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "98px"
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    color: "#000000",
                    background: "#FFFFFF",
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    justifyContent: "left"
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    maxHeight: "500px",
                    "&::-webkit-scrollbar": {
                        width: 5,
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#9AA0A6"
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "black",
                        borderRadius: "15px",
                    },
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontFamily: "TWKLausanne-550"
                },
                h2: {
                    fontFamily: "TWKLausanne-550"
                },
                h3: {
                    fontFamily: "TWKLausanne-550"
                },
                h4: {
                    fontFamily: "TWKLausanne-550"
                },
                h5: {
                    fontFamily: "TWKLausanne-550"
                }
            }
        }
    },
});


export const webUsbIsSupported = (window.navigator.usb !== undefined);
let notSupportedText = <h2>{"WebUSB is not supported on this browser. See: "}<a
    href={"https://caniuse.com/webusb"}>https://caniuse.com/webusb</a></h2>;


/*
 * Base rendered
 */
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <AppContextProvider>
                <BrowserRouter>
                    <Routes>
                        {
                            webUsbIsSupported ?
                                <Route element={<Root/>}>
                                    <>
                                        <Route path={"/"} element={<Home/>}/>
                                        <Route path={"/demo/:demoId"} element={<Demo/>}/>
                                        <Route path={"/send-command"} element={<DeveloperZone/>}/>
                                    </>

                                </Route>
                                :
                                <>
                                    <Route path={"/"} element={
                                        <Grid container style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minWidth: "100vw",
                                            maxWidth: "100vw",
                                            height: "100vh",
                                        }} md={2}>
                                            <Grid item>
                                                <img alt={"Evam"} height={"50px"}
                                                     src={process.env.PUBLIC_URL + "EvamLogo.svg"}
                                                     style={{verticalAlign: "middle"}}/>
                                            </Grid>
                                            <Grid item>
                                                <div>
                                                    {notSupportedText}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    }/>
                                </>
                        }
                    </Routes>
                </BrowserRouter>
            </AppContextProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
