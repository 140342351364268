import React from "react";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import {DemoStepGroupData, StepData} from "../config/demoData";
import CommandCenter from "../utils/commandCenter";
import ButtonSecondary from "./ButtonSecondary";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import {CommandSendStatus, useCommandSendStatus} from "../hooks/useCommandSendStatus";

type DemoStepGroupProps = {
    demoStepGroup: DemoStepGroupData;
    index:number;
};

/**
 * Component - Set of steps within a Demo
 */
export default function DemoStepGroup(props: DemoStepGroupProps) {
    const demoStepGroupData = props.demoStepGroup;
    const {index = 0} = props;
    const { prepareAndSendCommand } = CommandCenter();
    const StepSendButton = ({step}:{step:StepData}) => {
        const {commandSendStatus:stepSendStatus, setCommandSendStatus:setStepSendStatus} = useCommandSendStatus(3*1000);
        return(
            <ButtonSecondary
                data-testid={step.name.toString().toLowerCase().replaceAll(" ", "-")}
                style={{width:'270px',height:'76px', fontSize:'120%', marginBottom:'32px'}} variant="contained" onClick={async (_: any) => {
                setStepSendStatus(CommandSendStatus.SENDING);
                await prepareAndSendCommand(step.command).then(()=>{
                    setStepSendStatus(CommandSendStatus.SENT);
                }).catch((e)=>{
                    console.log(e)
                    setStepSendStatus(CommandSendStatus.FAILED);
                });
            }}>{stepSendStatus === CommandSendStatus.SENDING ? <CircularProgress style={{color:'white'}}/> : stepSendStatus === CommandSendStatus.SENT ? <CheckCircleRoundedIcon sx={{fontSize:'48px',bgcolor:'transparent', color:'white'}}/> : stepSendStatus === CommandSendStatus.FAILED ? <CancelRoundedIcon sx={{fontSize:'48px',bgcolor:'transparent', color:'white'}}/> : step.name }</ButtonSecondary>
        )
    }

    return (
        <Box>
            <Typography variant="h6" marginBottom={'32px'}>{(index+1) + '. '}{demoStepGroupData.name}</Typography>
            <Grid container spacing={3} marginBottom={'48px'}>
                {demoStepGroupData.stepsData.map((step) => (
                    <Grid key={step.name} item>
                        <Box>
                            <Typography variant="subtitle2" textAlign={'center'} marginBottom={'8px'} fontFamily='TWKLausanne-300'>
                                {step.description + ' '}{!step.mandatory &&  <Typography variant={'inherit'} display='inline' fontFamily='TWKLausanne-550'>(optional)</Typography>}
                            </Typography>
                            <StepSendButton step={step}/>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
