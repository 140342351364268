import {Box, CircularProgress, Stack, Typography} from "@mui/material";
import React from "react";
import demos from "../config/demoData";
import CommandCenter from "../utils/commandCenter";
import {useLocation} from "react-router-dom";
import DemoStepGroup from "./DemoStepGroup";
import ButtonPrimary from "./ButtonPrimary";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {CommandSendStatus, useCommandSendStatus} from "../hooks/useCommandSendStatus";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

export async function getStaticPaths() {
    return {
        paths: [{ params: { id: '1' } }, { params: { id: '2' } }],
        fallback: false, // can also be true or 'blocking'
    }
}
export async function getStaticProps() {
    return {
        props: {}, // will be passed to the page component as props
    }
}

/**
 * Demo component for a single Demo object
 */
export default function Demo() {
    const { prepareAndSendCommand } = CommandCenter();
    const location = useLocation()


    const pathElements = location.pathname.split("/")
    const demoId = pathElements[pathElements.length-1]
    const demo = demos.find((d) => {
        return d.id === demoId;
    });

    const {commandSendStatus:sceneStartStatus,setCommandSendStatus:setSceneStartStatus
    } = useCommandSendStatus(10*1000);

    //console.log(JSON.stringify(demos));
    if (demo === undefined || demo === null) {
        return (
            <React.Fragment>
                <p>No demo found for id {demoId} in demos: {demos[0].id}</p>
            </React.Fragment>
        );
    }

    const groups = demo.demoStepGroupsData;

    return (
        <React.Fragment>
            <Box marginLeft={5} marginTop={'32px'}>
                <Typography variant="h2">{demo.name}</Typography>
            </Box>
            <Box marginLeft={5} marginTop={'64px'}>
                <ButtonPrimary style={{width: '227px', height: '54px',fontSize:'110%'}} variant="contained" onClick={async (_: any) => {
                    demo.setupCommands.forEach(async (command) => {
                        setSceneStartStatus(CommandSendStatus.SENDING);
                        await prepareAndSendCommand(command).then(()=>{
                            setSceneStartStatus(CommandSendStatus.SENT);
                        }).catch(()=>{setSceneStartStatus(CommandSendStatus.FAILED)});
                    });
                }}>{sceneStartStatus === CommandSendStatus.SENDING ? <CircularProgress style={{color:'white'}}/> : sceneStartStatus === CommandSendStatus.SENT ? <CheckCircleRoundedIcon sx={{bgcolor:'transparent', color:'white', borderRadius:'100%',fontSize:'48px'}}/> : sceneStartStatus === CommandSendStatus.FAILED ? <CancelRoundedIcon sx={{bgcolor:'transparent', color:'white', borderRadius:'100%',fontSize:'48px'}}/> : 'Start | Restart Scene'}</ButtonPrimary>
            </Box>
            <Box marginLeft={5} marginTop={'120px'}>
                <Typography color={'#010101'} fontFamily={'TWKLausanne-400'} marginBottom={'32px'}>Control the flow</Typography>
                <Stack spacing={4} direction="column">
                    {groups.map((group,index) => (
                        <DemoStepGroup key={group.name} demoStepGroup={group} index={index}/>
                    ))}
                </Stack>
            </Box>
        </React.Fragment>
    );
}
