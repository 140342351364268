import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Box, useTheme
} from "@mui/material";
import React from "react";
import demos from "../config/demoData";
import {useNavigate} from "react-router-dom";
import ButtonSecondary from "../components/ButtonSecondary";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/**
 * Page containing all demos
 */
export default function Demos() {
    const navigate = useNavigate();

    const theme = useTheme();

    return (
        <Box paddingTop={0} paddingBottom={5}>
            <Typography variant="h2">Select your demo</Typography>
            <Grid container spacing={3} marginTop="10px">
                {demos.map((demo) => (
                    <Grid key={demo.id} item>
                        <Card sx={{
                            maxWidth: 275,
                            background: `#F1F3F4`,
                            boxShadow: "none",
                            transition: "0.3s",
                            "&:hover": {
                                background: theme.palette.secondary.main.toString()
                            }
                        }}>
                            <CardContent sx={{
                                padding: "32px 32px 24px 32px"
                            }}>
                                <Typography variant="h5">
                                    {demo.name}
                                </Typography>
                            </CardContent>
                            <CardActions sx={{
                                padding: "0px 32px 32px 32px"
                            }}>
                                <ButtonSecondary data-testid={'navigate-to-demo-' + demo.id + '-button'} size="small" onClick={() => {
                                    navigate("/demo/" + demo.id);
                                }}
                                >Lets start <ArrowForwardIcon
                                    style={{fontSize: "inherit", verticalAlign: "text-bottom"}}/> </ButtonSecondary>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
