import {useContext} from "react";
import {format} from "date-fns";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../framework/context/AppContext";

/**
 * Utility class for formatting and sending commands to an Android device
 * running Vehicle Services
 */
export default function CommandCenter() {

    const router = useNavigate();

    async function prepareAndSendCommand(command: any) {
        let preparedCommand = await prepareCommand(command);
        await sendCommandJson(preparedCommand);
    }

    async function prepareCommand(
        command: any
    ): Promise<any> {
        if (command.type !== "comcoder") {
            return command;
        }

        let encodedRawCommand = getEncodedMessage(command.raw);
        return {type: command.type, raw: encodedRawCommand};
    }

    function getEncodedMessage(rawCommand: string) {
        const now = new Date();
        const currTimeMatches = [
            ...rawCommand.matchAll(RegExp("{CURTIME[+-][0-9]+}", "g")),
        ];
        const currTimesMap = new Map(
            currTimeMatches.map((match) => {
                let addedSeconds;
                if (match[0].toString().includes("+")) {
                    addedSeconds = Number.parseInt(
                        match[0].toString().replaceAll("}", "").split("+")[1]
                    );
                } else {
                    addedSeconds = -Number.parseInt(
                        match[0].toString().replaceAll("}", "").split("-")[1]
                    );
                }
                let newTime = new Date(now.getTime() + addedSeconds * 1000);
                const formatedTime = format(newTime, "HH:mm:ss.SSS");
                return [match[0], formatedTime];
            })
        );

        let newCommand = rawCommand;
        currTimesMap.forEach((value: string, key: string) => {
            newCommand = newCommand.replaceAll(key, value);
        });

        //Replace dates
        newCommand = newCommand.replaceAll(
            "{CURDATE}",
            `${format(now, "yyyy-MM-dd")}`
        );

        // Replace all delimiters with actual value
        newCommand = newCommand.replaceAll("{0x16}", "\x16");
        newCommand = newCommand.replaceAll("{0x15}", "\x15");
        newCommand = newCommand.replaceAll("{0x14}", "\x14");
        newCommand = newCommand.replaceAll("{0x13}", "\x13");
        newCommand = newCommand.replaceAll("{0x12}", "\x12");

        let payload = "820402810B639C";

        let chars = [...newCommand];
        console.log("get chars: " + chars.length);
        for (let i = 0; i < chars.length; ++i) {
            let newChars = newCommand
                .codePointAt(i)
                ?.toString(16)
                .toUpperCase();
            if (newChars !== undefined) {
                payload += newChars;
            }
        }

        return [
            `+CTSDSR: 12,8885651,3,240000109888565,1,${payload.length * 4}`,
            `${payload}`,
        ];
    }

    let context = useContext(AppContext);
    let adb = context.adbDeviceRepository.get();

    async function sendCommandJson(command: Object) {
        let com = `echo '${JSON.stringify(
            command
        )}' | netcat -q 1 localhost 15556`;
        await sendCommandInner(com);
    }

    async function sendCommandInner(com: string) {
        if (adb == null) {
            alert("No device connected");
            router("/");
            return Promise.resolve();
        }
        let res = await adb.device.subprocess.spawnAndWait(com);

        if (res.exitCode === 1) {
            throw new Error("Command failed to execute");
        }

        console.log(com);
        console.log(res.exitCode);
    }

    return {prepareAndSendCommand, getEncodedMessage};
}


