/**
 * Interface describing a Demo
 */
export interface DemoData {
    id: string;
    name: string;
    demoStepGroupsData: Array<DemoStepGroupData>;
    setupCommands: Array<RawDemoCommandData | ValueDemoCommandData>;

}

/**
 * Interface describing a step within a Demo
 */
export interface DemoStepGroupData {
    name: string;
    stepsData: Array<StepData>;
}


/**
 * Data for a Step
 */
export interface StepData {
    name: string;
    description: string;
    mandatory: boolean;
    command: RawDemoCommandData | ValueDemoCommandData;
}

/**
 * Interface for a raw command
 */
export interface RawDemoCommandData {
    type: string;
    raw: any;
}

/**
 * Interface for a demo command value
 */
export interface ValueDemoCommandData {
    type: string;
    value: any;
}

/**
 * Demos available to the user
 */
const demos: Array<DemoData> = [
    {
        id: "1",
        name: "Fire department Sweden",
        setupCommands: [
            {
                type: "reset",
                raw: "",
            },
            {
                type: "location",
                value: {latitude: 59.361892, longitude: 17.988739},
            },
        ],
        demoStepGroupsData: [
            {
                name: "New call-out",
                stepsData: [
                    {
                        name: "New call-out",
                        description: "Generate new call-out",
                        mandatory: true,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Trafikolycka{0x13}Personbil{0x13}Övrigt{0x13}{0x13}{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}",
                        },
                    },
                    {
                        name: "Add info",
                        description: "Add call-out info",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Trafikolycka{0x13}Personbil{0x13}Övrigt{0x13}{0x13}Tre bilar inblandade. Två skadade. En person fastklämd enligt ambulans på plats.{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}",
                        },
                    },
                    {
                        name: "Add unit",
                        description: "New unit has joined",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Trafikolycka{0x13}Personbil{0x13}Övrigt{0x13}{0x13}Tre bilar inblandade. Två skadade. En person fastklämd enligt ambulans på plats.{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}101-0090{0x16}Framme{0x16}Stockholm{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}",
                        },
                    },
                    {
                        name: "Add breakpoint",
                        description: "Add breakpoint",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Trafikolycka{0x13}Personbil{0x13}Övrigt{0x13}{0x13}Tre bilar inblandade. Två skadade. En person fastklämd enligt ambulans på plats. Avvakta skyddsfordon vid brytpunkt.{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}101-0090{0x16}Framme{0x16}Stockholm{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}La = 59° 21,8576'N    Lo = 018° 1,6015'E{0x13}Parkering avfart Haga Norra{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}",
                        },
                    },
                ],
            },
            {
                name: "At site",
                stepsData: [
                    {
                        name: "At site",
                        description: "Arrive at site",
                        mandatory: true,
                        command: {
                            type: "route",
                            value: [
                                {
                                    latitude: 59.365038,
                                    longitude: 18.022505,
                                    sleep: 4000,
                                },
                                {
                                    latitude: 59.365262,
                                    longitude: 18.022185,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.36552,
                                    longitude: 18.021849,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.367209,
                                    longitude: 18.020687,
                                    sleep: 3000,
                                },
                                {
                                    latitude: 59.367749,
                                    longitude: 18.020568,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.368101,
                                    longitude: 18.020516,
                                    sleep: 1000,
                                },
                            ],
                        },
                    },
                    {
                        name: "At breakpoint",
                        description: "Arrive at breakpoint",
                        mandatory: false,
                        command: {
                            type: "route",
                            value: [
                                {
                                    latitude: 59.363155,
                                    longitude: 18.025612,
                                    sleep: 6000,
                                },
                                {
                                    latitude: 59.363493,
                                    longitude: 18.02568,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.363717,
                                    longitude: 18.025761,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.364143,
                                    longitude: 18.025904,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.364146,
                                    longitude: 18.026173,
                                    sleep: 1500,
                                },
                                {
                                    latitude: 59.364153,
                                    longitude: 18.026436,
                                    sleep: 1500,
                                },
                                {
                                    latitude: 59.364211,
                                    longitude: 18.026603,
                                    sleep: 1500,
                                },
                                {
                                    latitude: 59.364274,
                                    longitude: 18.026719,
                                    sleep: 1500,
                                },
                            ],
                        },
                    },
                ],
            },
            {
                name: "Arrive at station",
                stepsData: [
                    {
                        name: "Arrive at home station",
                        description: "Arrive at home station",
                        mandatory: true,
                        command: {
                            type: "location",
                            value: {
                                latitude: 59.361892,
                                longitude: 17.988739,
                            },
                        },
                    },
                    {
                        name: "Garage door opening",
                        description: "Garage door opening at home station",
                        mandatory: false,
                        command: {
                            type: "location",
                            value: {
                                latitude: 59.361816,
                                longitude: 17.988036,
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        id: "2",
        name: "Ambulance Sweden",
        setupCommands: [
            {
                type: "reset",
                raw: "",
            },
            {
                type: "location",
                value: {latitude: 59.361892, longitude: 17.988739},
            },
        ],
        demoStepGroupsData: [
            {
                name: "New call-out",
                stepsData: [
                    {
                        name: "New call-out",
                        description: "Generate new call-out",
                        mandatory: true,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10036{0x13}3{0x13}34{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}Test{0x13}Testkort{0x13}Man ca 60{0x13}1{0x13}Bröstsmärtor{0x13}Smärtor i skuldror, armar eller käkar{0x13}Övrigt{0x13}{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}240-1-9230022{0x16}230 SjvIns-1{0x15}240-1-9888565{0x16}Sthm RAPS-01{0x13}{0x13}{CURDATE} {CURTIME+60}{0x13}08-123456{0x13}{0x13}TetraR3E4{0x13}{0x13}{0x14}",
                        },
                    },
                    {
                        name: "Add info",
                        description: "Add call-out info",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw: "{0x12}10036{0x13}3{0x13}34{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}Test{0x13}Testkort{0x13}Man ca 60{0x13}1{0x13}Bröstsmärtor{0x13}Smärtor i skuldror, armar eller käkar{0x13}Övrigt{0x13}Risk av hjärtinfarkt{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}Test Medicinsk{0x13}Test Räddning{0x13}240-1-9230022{0x16}230 SjvIns-1{0x15}240-1-9888565{0x16}Sthm RAPS-01{0x13}{0x13}{CURDATE} {CURTIME+0}{0x13}08-123456{0x13}{0x13}TetraR3E4{0x13}{0x13}{0x14}"
                        },
                    },
                    {
                        name: "Add unit",
                        description: "New unit has joined",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw: "{0x12}10036{0x13}3{0x13}34{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}Test{0x13}Testkort{0x13}Man ca 60{0x13}1{0x13}Bröstsmärtor{0x13}Smärtor i skuldror, armar eller käkar{0x13}Övrigt{0x13}Risk av hjärtinfarkt{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}Test Medicinsk{0x13}Test Räddning{0x13}240-1-9230022{0x16}230 SjvIns-1{0x15}240-1-9888565{0x16}Sthm RAPS-01{0x13}{0x13}{CURDATE} {CURTIME+0}{0x13}08-123456{0x13}314-9610{0x16}Framme{0x16}Stockholm{0x13}TetraR3E4{0x13}{0x13}{0x14}"
                        },
                    },
                    {
                        name: "Add Leave-Patient",
                        description: "Add Leave-Patient",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10036{0x13}3{0x13}34{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}Test{0x13}Testkort{0x13}Man ca 60{0x13}1{0x13}Bröstsmärtor{0x13}Smärtor i skuldror, armar eller käkar{0x13}Övrigt{0x13}Hög risk av hjärtinfarkt. Andningsproblem, osammanhängande tal.{0x13}E4 Norrgående{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{CURDATE} {CURTIME+0}{0x13}Eugeniavägen{0x13}164{0x13}Stockholm-Karolinska{0x13}Stockholm{0x13}{CURDATE} {CURTIME+300}{0x13}{0x13}La = 59° 21,0136'N  Lo = 18° 2,0541'E{0x13}{0x13}Test Medicinsk{0x13}Framme infart{0x13}240-1-9230022{0x16}230 SjvIns-1{0x15}240-1-9888565{0x16}Sthm RAPS-01{0x13}{0x13}{CURDATE} {CURTIME+30}{0x13}08-123456{0x13}314-9610{0x16}Framme{0x16}Stockholm{0x13}TetraR3E4{0x13}Händelsen inträffade under lunchen{0x13}{0x14}",
                        },
                    },
                ],
            },
            {
                name: "At site",
                stepsData: [
                    {
                        name: "At site",
                        description: "Arrive at site",
                        mandatory: true,
                        command: {
                            type: "route",
                            value: [
                                {
                                    latitude: 59.365038,
                                    longitude: 18.022505,
                                    sleep: 4000,
                                },
                                {
                                    latitude: 59.365262,
                                    longitude: 18.022185,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.36552,
                                    longitude: 18.021849,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.367209,
                                    longitude: 18.020687,
                                    sleep: 3000,
                                },
                                {
                                    latitude: 59.367749,
                                    longitude: 18.020568,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.368101,
                                    longitude: 18.020516,
                                    sleep: 1000,
                                },
                            ],
                        },
                    },
                    {
                        name: "Exit site",
                        description: "Patient picked up, leave the site",
                        mandatory: false,
                        command: {
                            type: "route",
                            value: [
                                {
                                    latitude: 59.368101,
                                    longitude: 18.020516,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.370133,
                                    longitude: 18.020251,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.371338,
                                    longitude: 18.020006,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.372764,
                                    longitude: 18.019125,
                                    sleep: 1000,
                                },
                            ],
                        },
                    },
                ],
            },
        ],
    },
    {
        id: "3",
        name: "Fire department UK",
        setupCommands: [
            {
                type: "reset",
                raw: "",
            },
            {
                type: "location",
                value: {latitude: 59.361892, longitude: 17.988739},
            },
        ],
        demoStepGroupsData: [
            {
                name: "New call-out",
                stepsData: [
                    {
                        name: "New call-out",
                        description: "Generate new call-out",
                        mandatory: true,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Traffic accident{0x13}Passenger car{0x13}Miscellaneous{0x13}{0x13}{0x13}E4 Northbound{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}"
                        },
                    },
                    {
                        name: "Add info",
                        description: "Add call-out info",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Traffic accident{0x13}Passenger car{0x13}Miscellaneous{0x13}{0x13}Three cars involved. Two injured. One person trapped according to the ambulance on-site.{0x13}E4 Northbound{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}",
                        },
                    },
                    {
                        name: "Add unit",
                        description: "New unit has joined",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Traffic accident{0x13}Passenger car{0x13}Miscellaneous{0x13}{0x13}Three cars involved. Two injured. One person trapped according to the ambulance on-site.{0x13}E4 Northbound{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}101-0090{0x16}At site{0x16}Stockholm{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}",
                        },
                    },
                    {
                        name: "Add breakpoint",
                        description: "Add breakpoint",
                        mandatory: false,
                        command: {
                            type: "comcoder",
                            raw:
                                "{0x12}10200{0x13}18{0x13}1358263{0x13}1{0x13}{CURDATE} {CURTIME+0}{0x13}{CURDATE} {CURTIME-20}{0x13}1{0x13}Traffic accident{0x13}Passenger car{0x13}Miscellaneous{0x13}{0x13}Three cars involved. Two injured. One person trapped according to the ambulance on-site. Wait for traffic safety vehicles at the breakpoint.{0x13}E4 Northbound{0x13}Stockholm{0x13}Stockholm{0x13}{0x13}La = 59° 22,0949'N    Lo = 018° 1,2303'E{0x13}{0x13}101-0090{0x16}At site{0x16}Stockholm{0x13}240-1-9230022{0x16}230 RtjIns-1{0x15}240-1-9030011{0x16}Sthm RAPS-01{0x13}{0x13}{0x13}{0x13}La = 59° 21,8576'N    Lo = 018° 1,6015'E{0x13}Parking exit Haga Norra{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x13}{0x14}",
                        },
                    },
                ],
            },
            {
                name: "At site",
                stepsData: [
                    {
                        name: "At site",
                        description: "Arrive at site",
                        mandatory: true,
                        command: {
                            type: "route",
                            value: [
                                {
                                    latitude: 59.365038,
                                    longitude: 18.022505,
                                    sleep: 4000,
                                },
                                {
                                    latitude: 59.365262,
                                    longitude: 18.022185,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.36552,
                                    longitude: 18.021849,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.367209,
                                    longitude: 18.020687,
                                    sleep: 3000,
                                },
                                {
                                    latitude: 59.367749,
                                    longitude: 18.020568,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.368101,
                                    longitude: 18.020516,
                                    sleep: 1000,
                                },
                            ],
                        },
                    },
                    {
                        name: "At breakpoint",
                        description: "Arrive at breakpoint",
                        mandatory: false,
                        command: {
                            type: "route",
                            value: [
                                {
                                    latitude: 59.363155,
                                    longitude: 18.025612,
                                    sleep: 6000,
                                },
                                {
                                    latitude: 59.363493,
                                    longitude: 18.02568,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.363717,
                                    longitude: 18.025761,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.364143,
                                    longitude: 18.025904,
                                    sleep: 1000,
                                },
                                {
                                    latitude: 59.364146,
                                    longitude: 18.026173,
                                    sleep: 1500,
                                },
                                {
                                    latitude: 59.364153,
                                    longitude: 18.026436,
                                    sleep: 1500,
                                },
                                {
                                    latitude: 59.364211,
                                    longitude: 18.026603,
                                    sleep: 1500,
                                },
                                {
                                    latitude: 59.364274,
                                    longitude: 18.026719,
                                    sleep: 1500,
                                },
                            ],
                        },
                    },
                ],
            },
            {
                name: "Arrive at station",
                stepsData: [
                    {
                        name: "Arrive at home station",
                        description: "Arrive at home station",
                        mandatory: true,
                        command: {
                            type: "location",
                            value: {
                                latitude: 59.361892,
                                longitude: 17.988739,
                            },
                        },
                    },
                    {
                        name: "Garage door opening",
                        description: "Garage door opening at home station",
                        mandatory: false,
                        command: {
                            type: "location",
                            value: {
                                latitude: 59.361816,
                                longitude: 17.988036,
                            },
                        },
                    },
                ],
            },
        ],
    }
];

export default demos;
