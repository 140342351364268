import {AdbDevice, AdbDeviceRepository} from "../../data/adbDeviceRepository";

export class InMemoryAdbDeviceRepository implements AdbDeviceRepository {
    private device: AdbDevice|null = null

    get(): AdbDevice | null {
        return this.device;
    }

    set(device: AdbDevice | null): void {
        this.device = device
    }

}