import {AppContext, DEFAULT_APP_CONTEXT_VALUE} from "./AppContext";
import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {AdbDevice} from "../../data/adbDeviceRepository";
import {webUsbIsSupported} from "../../index";
import {connect} from "../../utils/connect";


interface AdbDeviceDependencies {
    device: AdbDevice | null,
    setDevice: Dispatch<SetStateAction<AdbDevice | null>>
    isReconnecting: boolean
}

const DEFAULT_ADB_DEVICE_CONTEXT_VALUE: AdbDeviceDependencies = {
    device: null,
    setDevice: () => undefined,
    isReconnecting: false
};

export const AdbDeviceContext = createContext<AdbDeviceDependencies>(
    DEFAULT_ADB_DEVICE_CONTEXT_VALUE
);


export const AdbDeviceProvider = ({children}: { children: React.ReactNode }) => {
        const context = useContext(AppContext);
        const [adbDevice, setAdbDevice] = useState<AdbDevice | null>(context.adbDeviceRepository.get());
        const [isReconnecting, setIsReconnecting] = useState(false)

        useEffect(() => {
            /**
             * This useEffect(componentDidMount) defines the webUSB onDisconnect callback
             */
            if (webUsbIsSupported) {
                /**
                 * window.navigator.usb is undefined on Firefox.
                 * @param e USBConnectionEvent
                 */
                window.navigator.usb.ondisconnect = (e) => {
                    if (adbDevice !== null) {
                        //if the disconnected device is the Adb device connected then setAdbDevice(null)
                        if (e.device.serialNumber === adbDevice.serial) {
                            setAdbDevice(null);

                            // Attempt reconnecting with delay
                            setIsReconnecting(true)
                            let p = Promise.reject<AdbDevice>()
                            for (let i = 0; i < 3; i++) {
                                p = p.catch(() => connect(true)).catch((reason) =>
                                    new Promise((_, reject) => {
                                        setTimeout(reject.bind(null, reason), 2000)
                                    })
                                )
                            }
                            p.then(setAdbDevice)
                                .catch(() => {
                                    window.alert(adbDevice.device.model + " has been disconnected. \n If this keeps happening it is possible your connector cable is damaged.");
                                    window.location.pathname = '/'
                                }).finally(() => {
                                setIsReconnecting(false)
                            })
                        }
                    }
                };
                return (() => {
                    window.navigator.usb.ondisconnect = null;
                });
            }
        }, [adbDevice])

        useEffect(() => {
            if (window.navigator.usb) {
                let devices = window.navigator.mediaDevices;
                console.log(devices);
            }
        }, []);

        useEffect(() => {
            context.adbDeviceRepository.set(adbDevice);
        }, [adbDevice, context.adbDeviceRepository]);

        return (
            <AdbDeviceContext.Provider value={{device: adbDevice, setDevice: setAdbDevice, isReconnecting}}>
                {children}
            </AdbDeviceContext.Provider>
        );

    }
;
export const AppContextProvider = ({children}: { children: React.ReactNode }) => {
    return (
        <AppContext.Provider value={DEFAULT_APP_CONTEXT_VALUE}>
            <AdbDeviceProvider>
                {children}
            </AdbDeviceProvider>
        </AppContext.Provider>
    );
};