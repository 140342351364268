import React, {useMemo} from "react";
import UAParser from "ua-parser-js";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const ScrcpyInstructions = ({tcpIp}: { tcpIp: string }) => {
    const userOS = useMemo(() => {
        return new UAParser().getOS().name
    }, [])

    const terminal = {
        "Windows": "cmd",
    }[userOS || ''] || "Terminal"

    const command = {
        "Mac OS": `ADB_LIBUSB=0 scrcpy --tcpip=${tcpIp}`,
    }[userOS || ''] || `scrcpy --tcpip=${tcpIp}`

    return (
        <>
            <Typography fontFamily={"TWKLausanne-550"} fontSize={"21px"}>
                Your device is now ready for screen mirroring
            </Typography>
            <Typography>
                You will need to install <em>scrcpy</em> on your computer:<br/><a
                href={"https://github.com/Genymobile/scrcpy?tab=readme-ov-file#get-the-app"}>https://github.com/Genymobile/scrcpy?tab=readme-ov-file#get-the-app</a>
            </Typography>
            <Typography fontFamily={"TWKLausanne-550"}>
                Note: The scrcpy program is not developed by Evam, we do not offer support and help on its setup and usage. Refer to the link above for documentation.
            </Typography>
            <Typography>
                Start by opening a terminal in which you can run scrcpy (such as {terminal}, refer to the "Run" section in your OS installation instructions).
            </Typography>
            <Typography>
                Then run:
            </Typography>
            <Box component={"code"}>
                {command}
            </Box>
            <Typography>
                If this is the first time you perform screen mirroring between your computer and this Evam device,
                you may
                need to accept a prompt in the Evam device to authorize the connection. If this is the case, make
                sure your
                Evam device's screen is unlocked and accept the prompt, then run the command above again.
            </Typography></>
    )
}