import Box from "@mui/material/Box";
import TopBar from "./TopBar";
import {Outlet} from "react-router-dom";

/**
 * Root view for each page
 */
export function Root() {

    return <Box>
        <TopBar/>
        <Box sx={{padding: "1em", marginX: "10%"}}>
            <Outlet/>
        </Box></Box>;
}
