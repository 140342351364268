import * as React from 'react'
import Button, {ButtonProps} from "@mui/material/Button";

interface ButtonSecondaryProps extends ButtonProps {}
export default function ButtonSecondary({children, ...rest}:ButtonSecondaryProps){
    return(
        <Button {...rest} disableRipple sx={{
            background:'black',
            color: 'white',
            paddingLeft:'24px',
            paddingRight:'24px',
            gap:'8px',
            '&:hover':{
                background:'#3C4043'
            },
            maxWidth: '270px',
            maxHeight: '76px',
            textTransform:'none',
        }}>{children}</Button>
    )
}