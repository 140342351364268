import React, {useContext, useEffect, useMemo, useState} from "react";
import {CssBaseline} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {connect} from "../utils/connect";
import Demos from "./Demos";
import ButtonPrimary from "../components/ButtonPrimary";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {AdbDeviceContext} from "../framework/context/AppContextProvider";
import {BsFillExclamationTriangleFill} from "react-icons/bs";
import ButtonSecondary from "../components/ButtonSecondary";
import {ScrcpyInstructions} from "./components/ScrcpyInstructions";
import {ErrorMessage} from "./components/ErrorMessage";
import UAParser from "ua-parser-js";

/**
 * Home Component for the Home page
 */
export function Home() {
    const {device: adbDevice, setDevice: setAdbDevice, isReconnecting} = useContext(AdbDeviceContext);
    const [err, setErr] = useState<Error | undefined>(undefined);
    const [showScreenMirrorInstructions, setShowScreenMirrorInstructions] = useState(false)
    const [connected, setConnected] = useState<boolean>(false);
    const [port, setPort] = useState<number>()
    const [ip, setIp] = useState<string>()
    const tcpIp = ip && port ? `${ip}:${port}` : undefined

    const userOS = useMemo(() => {
        return new UAParser().getOS().name
    }, [])

    const networkDevice = {
        "Mac OS": "wlan",
    }[userOS || ''] || "rndis"

    useEffect(() => {
        if (adbDevice) {
            setConnected(true);

            // Check if remote adb available
            Promise.all([
                adbDevice?.device.subprocess.spawnAndWaitLegacy("getprop service.adb.tcp.port") || '',
                adbDevice?.device.subprocess.spawnAndWaitLegacy(`ifconfig | grep '${networkDevice}' -A 5 | grep -Eo 'inet (addr:)?([0-9]*\\.){3}[0-9]*' | grep -Eo '([0-9]*\\.){3}[0-9]*' | grep -v '127.0.0.1'`) || '',
            ]).then(([port, ip]) => {
                const portNumber = Number.parseInt(port.trim())
                setPort(portNumber)
                setIp(ip.trim())
            })
        } else {
            setConnected(false);
            setPort(undefined)
            setIp(undefined)
        }
    }, [adbDevice, networkDevice]);

    return (
        <React.Fragment>
            <CssBaseline/>
            <Box marginLeft={5} marginRight={5}>
                <Box>
                    <Box marginTop={5} marginBottom={5}>
                        <Typography variant="h2">Connect your device</Typography>
                        <Typography fontSize={'16px'} color={"#707070"} variant="body1"
                                    sx={{wordBreak: "break-word", whiteSpace: "pre-line"}}>
                            Connect your device with USB to start a demo. Remember, Evam
                            is optimized for the needs of first responders. {"\n"} Helps you
                            stay in control, is usable. And powers you into the digital
                            world.
                        </Typography>
                        <Typography fontSize={'16px'} color={"#707070"} variant="body1"
                                    sx={{wordBreak: "break-word", whiteSpace: "pre-line"}}>
                            Before connecting, make sure that:
                            <ul>
                                <li>Android Studio is not running in your computer (if you have it installed),</li>
                                <li>ADB is not running in the background,</li>

                                { (userOS !== "Mac OS") ?
                                    <>
                                        <li>Your Evam device is connected to your computer over USB,</li>
                                        <li>Your Evam device has USB tethering enabled under Connections &gt; Mobile
                                            Hotspot and
                                            Tethering,
                                        </li>
                                        <li>Your computer is connected to the Evam device's USB tethering (it should
                                            show as an Ethernet connection in your computer).
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li>Your computer is connected to a Wi-Fi network that is not a public hotspot,</li>
                                        <li>Your Evam device is connected to the same Wi-Fi Network as your computer.
                                        </li>

                                    </>
                                }

                            </ul>
                        </Typography>
                    </Box>
                    {err && <ErrorMessage error={err} marginBottom={3}/>}
                    <ButtonPrimary
                        disabled={connected || isReconnecting}
                        variant="contained"
                        onClick={async (_e: any) => {
                            await connect().then((device) => {
                                if (device === null) throw new Error("Device is null.");
                                setErr(undefined);
                                //This will now rerender component when the device updates
                                setAdbDevice(device);
                            }).catch((e: any) => {
                                setErr(e);
                            });
                        }}
                        style={{width: "222px", height: "51px", fontSize: '19px'}}
                    >
                        {(() => {
                            if (isReconnecting) return "Reconnecting..."
                            if (connected) return "Connected"
                            else return "Connect"
                        })()}
                    </ButtonPrimary>
                    {connected && <ButtonSecondary
                        style={{width: "300px", height: "51px", fontSize: '19px', marginLeft: '10px'}}
                        onClick={() => {
                            setShowScreenMirrorInstructions(true)
                            if (!port) {
                                // Restart adbd in tcpip mode:
                                adbDevice?.device.tcpip.setPort(5555)
                            }
                        }}>
                        Setup Screen Mirroring
                    </ButtonSecondary>}

                    <Box paddingBottom={"32px"}>
                        {err ? <Typography fontFamily={"TWKLausanne-300"}
                                           style={{opacity: 0.76, paddingTop: "12px"}}
                                           fontSize={"16px"} color={"#E10600"} sx={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}><BsFillExclamationTriangleFill color={"#E10600"}
                                                              style={{marginRight: "8px"}}/>{"Please try again."}
                            </Typography> :
                            <Typography paddingTop={"12px"} variant="body1" component="p"
                                        fontFamily={"TWKLausanne-300"}
                                        fontSize={'16px'}>{(adbDevice != null) ? <>
                                <CheckCircleIcon
                                    style={{
                                        fontSize: "inherit",
                                        verticalAlign: "text-bottom",
                                        marginRight: "4px"
                                    }}/> {adbDevice.device.model} {tcpIp && '(' + tcpIp + ')'}</> : <>
                                <CancelIcon
                                    style={{
                                        fontSize: "inherit",
                                        verticalAlign: "text-bottom",
                                        marginRight: "4px"
                                    }}/>{"No device paired"}</>}</Typography>}
                    </Box>
                    {(connected && Boolean(port) && showScreenMirrorInstructions) && (
                        <Box padding={5} display={"flex"} flexDirection={"column"} gap={"15px"} style={{
                            backgroundColor: "#F8F9FA",
                            borderRadius: "10px",
                        }}>
                            {!ip ? 'Failed to look up the ip address of the connected device. Make sure that your device has USB tethering enabled under Connections > Mobile Hotspot and Tethering, then try again.' :
                                <ScrcpyInstructions tcpIp={tcpIp!}/>}
                        </Box>)}
                </Box>
                <Box marginTop={"120px"}>
                    <Demos/>
                </Box>
            </Box>
        </React.Fragment>);
}